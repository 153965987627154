
<template lang="html">
    <div id="page-list">
        <div id="list-content" class="vx-card p-6 vs-con-loading__container">

            <vs-prompt :title="$t('actions.exportData')" class="export-options" @cancel="clearFields" @accept="exportToExcel" :accept-text="$t('actions.export')" :cancel-text="$t('actions.cancel')" @close="clearFields" :active.sync="activePrompt">
                <vs-input v-model="fileName" :placeholder="$t('actions.enterFilename')" class="w-full" />
                <v-select v-model="selectedFormat" :clearable="false" :options="formats" class="my-4" />
            </vs-prompt>

            <vs-row vs-type="flex" vs-justify="flex-end">
                <vs-switch color="success" v-model="status">
                    <span slot="on">{{$t('status.active')}}</span>
                    <span slot="off">{{$t('status.inactive')}}</span>
                </vs-switch>
            </vs-row>

            <div class="flex flex-wrap justify-between items-center">

                <div v-if="$store.state.AppActiveUser.type > 0"
                  class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addItem">
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                    <span class="ml-2 text-base text-primary">{{ $t('company.actions.new') }}</span>
                </div>

                <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
                    <vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" :label-placeholder="$t('navbar.search')" />
                    <vs-button class="mb-4 md:mb-0 mt-5" @click="activePrompt=true">{{ $t('actions.export') }}</vs-button>
                </div>
            </div>

            <ag-grid-vue
                ref="agGridTable"
                :gridOptions="gridOptions"
                class="ag-theme-material w-100 my-4 ag-grid-table"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :rowData="data"
                rowSelection="multiple"
                colResizeDefault="shift"
                :animateRows="true"
                :pagination="true"
                :paginationPageSize="paginationPageSize"
                :suppressPaginationPanel="true"
                :enableRtl="$vs.rtl">
            </ag-grid-vue>
            <vs-pagination
                :total="totalPages"
                v-model="currentPage" />
        </div>
    </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import i18n from "@/i18n/i18n";
import { getAgGridTranslation } from "@/utils/utils";
import vSelect from "vue-select";

// Cell Renderer
import CompanyCellActions from "./cell-renderer/CompanyCellActions.vue";

export default {
  components: {
    AgGridVue,
    vSelect,
    CompanyCellActions,
  },
  data: () => ({
    data: null,
    dataRaw: null,
    status: true,
    searchQuery: "",
    totalIntPages: 1,

    //Export2Excel
    activePrompt: false,
    fileName: "",
    formats: ["xlsx", "csv", "txt"],
    cellAutoWidth: true,
    selectedFormat: "xlsx",
    headerTitle: [
      "Id",
      i18n.t("company.type"),
      i18n.t("company.trade"),
      i18n.t("company.name"),
      i18n.t("company.employer_id"),
      i18n.t("company.state_registration"),
      i18n.t("common.phone") + " 1",
      i18n.t("common.phone") + " 2",
      i18n.t("company.email"),
      i18n.t("address.zipcode"),
      i18n.t("address.line"),
      i18n.t("address.district"),
      i18n.t("address.city"),
      i18n.t("address.state"),
      i18n.t("address.country"),
    ],
    headerVal: [
      "id",
      "typeTranslated",
      "trade",
      "name",
      "employer_id",
      "state_registration",
      "phone1",
      "phone2",
      "email",
      "zipcode",
      "street",
      "district",
      "city",
      "state",
      "country",
    ],

    // AgGrid
    gridOptions: {
      localeTextFunc: function (key, defaultValue) {
        return getAgGridTranslation(key, defaultValue);
      },
      suppressCellSelection: true,
    },
    gridApi: null,
    defaultColDef: {
      sortable: true,
      resizable: false,
      suppressMenu: true,
			floatingFilter: true,
    },
    columnDefs: [
      {
        headerName: i18n.t("common.id"),
        field: "id",
        width: 160,
				suppressSizeToFit: true,
        filter: true,
      },
      {
        headerName: i18n.t("company.type"),
        field: "typeTranslated",
        minWidth: 170,
        filter: true,
      },
      {
        headerName: i18n.t("company.trade"),
        field: "trade",
        minWidth: 300,
        filter: true,
      },
      {
        headerName: i18n.t("company.name"),
        field: "name",
        sort: "asc",
        minWidth: 350,
        filter: true,
				wrapText: true,
        autoHeight: true,
      },
      {
        headerName: i18n.t("company.employer_id"),
        field: "employer_id",
        minWidth: 230,
        filter: true,
      },
      {
        headerName: "Email",
        field: "email",
        minWidth: 250,
        filter: true,
      },
      {
        headerName: i18n.t("actions.title"),
        width: 100,
        field: "actions",
        suppressSizeToFit: true,
        cellRendererFramework: "CompanyCellActions",
      },
    ],
  }),
  watch: {
    status: function (val) {
      this.data = this.dataRaw.filter((x) => x.active == val);
    },
    "$store.state.windowWidth"(val) {
      this.resizeTable(val);
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if(this.totalIntPages){
        return this.totalIntPages;
      }
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
		resizeTable(width) {
      if (width <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("actions", null);
      } else {
        this.gridOptions.columnApi.setColumnPinned("actions", "right");
        this.gridOptions.api.sizeColumnsToFit();
      }
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    addItem() {
      this.$router.push({
        name: "company-new",
        params: { company: {}, new: true },
      });
    },
    loadData() {
      var self = this;

      this.$vs.loading({
        container: "#list-content",
        scale: 0.5,
      });

      this.$store
        .dispatch("httpRequest", { method: "get", url: "company/all" })
        .then((data) => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
          self.data = data.filter((x) => x.active == this.status);
          self.dataRaw = data;
          self.data.forEach((x) => {
            if (x.type == 0) x.typeTranslated = i18n.t("common.company");
            else x.typeTranslated = i18n.t("common.astec");
          });
          this.totalIntPages = Math.ceil(this.data.length / this.paginationPageSize);
        })
        .catch((error) => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger",
          });
        });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    exportToExcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const list = [];
        this.gridOptions.api
          .getModel()
          .rootNode.childrenAfterFilter.forEach((x) => {
            list.push(x.data);
          });

        const data = this.formatJson(this.headerVal, list);
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat,
        });
        this.clearFields();
      });
    },
    clearFields() {
      (this.fileName = ""), (this.selectedFormat = "xlsx");
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.loadData();
    this.resizeTable(this.$store.state.windowWidth);
  },
};
</script>

<style>
.ag-cell-wrap-text {
  word-break: break-word !important;
}
</style>